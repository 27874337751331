import { render, staticRenderFns } from "./AdminResetPassword.vue?vue&type=template&id=6f4f2ab5&"
import script from "./AdminResetPassword.vue?vue&type=script&lang=js&"
export * from "./AdminResetPassword.vue?vue&type=script&lang=js&"
import style0 from "./AdminResetPassword.vue?vue&type=style&index=0&id=6f4f2ab5&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports